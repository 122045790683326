import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from '../service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from './breadcrumb-service';
import { Breadcrumb } from './breadcrumb';
import { BreadcrumbPattern } from './breadcrumb-pattern';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html',
    styleUrl: './app.breadcrumb.component.scss'
})
export class AppBreadcrumbComponent {

    breadcrumbs: Breadcrumb[] = [];

    breadcrumbMessage: Breadcrumb = {} as Breadcrumb;
    
    @Input()
    breadcrumbPatterns: BreadcrumbPattern[] = [];

    @ViewChild('searchinput') searchInput!: ElementRef;
    searchActive: boolean = false;
    
    constructor(private router: Router,public layoutService: LayoutService, private translate: TranslateService, private breadcrumbService: BreadcrumbService) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(event => {
            this.breadcrumbs = this.generateBreadCrumbsFromUrlRegex(this.router.url);
        });
    }

    ngOnInit(){
        this.breadcrumbService.breadcrumbMessage$.subscribe((breadcrumbUpdate) => 
        {
            this.breadcrumbs.forEach((breadcrumb) => {
                if(breadcrumb.id == breadcrumbUpdate.id){
                    breadcrumb.label = breadcrumbUpdate.label;
                }
            })
        })
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    
    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    public generateBreadCrumbsFromUrlRegex(route: string) {
        let breadcrumbOutput: Breadcrumb[] = [];

        this.breadcrumbPatterns.forEach(breadcrumbPattern => 
            {
                route.match(breadcrumbPattern.pattern)?.forEach( match => 
                    {
                        breadcrumbOutput.push(
                            {
                                id: breadcrumbPattern.id,
                                label: this.translate.instant('breadcrumbs.'+breadcrumbPattern.id)?? "",
                                url: match
                            }
                        )
                    }
                );
            }
        )
        return breadcrumbOutput;
    }

    navigateTo(breadcrumb: Breadcrumb) {
        if(breadcrumb.url){
            this.router.navigate([breadcrumb.url]);
        }
    }
}
