import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Farm } from "src/app/domain/models/farm";
import { TurbineDetail } from "src/app/domain/models/turbine-detail";
import { IFarmRepository } from "src/app/domain/repositories/farm-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class FarmRepository implements IFarmRepository {
    constructor(private http: HttpClient) { }
    
    private buildUrl(farmId?: string): string {
        if (farmId)
            return `${environment.api}/farms/${farmId}`
        return `${environment.api}/farms`
    }
    
    private buildStorageUrl(): string {
        return `${environment.apiUpload}/files`
    }
    
    getAll(): Observable<Farm[]> {
        return this.http.get<Farm[]>(this.buildUrl());
    }
    
    getFarmDetail(farmId: string): Observable<Farm> {
        return this.http.get<Farm>(this.buildUrl(farmId)+'/detail');    
    }
    
    findFarmsByName(name: string): Observable<Farm[]> {
        return this.http.get<Farm[]>(this.buildUrl()+'?name='+name);
    }
    getTurbines(farmId: string): Observable<TurbineDetail[]> {
        return this.http.get<TurbineDetail[]>(this.buildUrl(farmId)+'/turbines');
    }
}