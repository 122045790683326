import { Observable } from "rxjs";
import { IJointRepository } from "src/app/domain/repositories/joint-repository.interface";
import { IJointService } from "./joint.service.interface";
import { Injectable } from "@angular/core";
import { Joint } from "src/app/domain/models/joint";
import { JointDetail } from "src/app/domain/models/joint-detail";
import { ReportResponse } from "src/app/domain/models/report-response";

@Injectable({
    providedIn: 'root'
  })

export class JointService implements IJointService {
    
    constructor(
        private jointRepository: IJointRepository
    ){ }


    getJointsByTurbineModelId(farmId: string, turbineId: string, turbineModelId: string): Observable<Joint[]> {
        return this.jointRepository.getJointsbyTurbineModelId(farmId, turbineId, turbineModelId);
    }

    getJointDetailsById(farmId: string, turbineId: string, jointId: string): Observable<JointDetail> {
        return this.jointRepository.getJointDetailsById(farmId, turbineId, jointId);
    }
    generateJointReport(farmId: string, turbineId: string, jointId: string): Observable<ReportResponse> {
        return this.jointRepository.generateJointReportURL(farmId, turbineId, jointId);
    }
}