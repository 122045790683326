import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReportResponse } from "src/app/domain/models/report-response";
import { TurbineDetail } from "src/app/domain/models/turbine-detail";
import { ITurbineRepository } from "src/app/domain/repositories/turbine-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TurbineRepository implements ITurbineRepository {

    private buildUrl( farmId?: string, turbineId?: string): string {
        if (turbineId)
            return `${environment.api}/farms/${farmId}/turbines/${turbineId}`
        return `${environment.api}/farms/${farmId}/turbines`
    }

    private buildStorageUrl(): string {
        return `${environment.apiUpload}/files`
    }

    constructor(private http: HttpClient) { }
    getAll(farmId: string): Observable<TurbineDetail[]> {
        throw new Error("Method not implemented.");
    }
    getTurbineDetail(farmId: string, turbineId: string): Observable<TurbineDetail> {
        return this.http.get<TurbineDetail>(this.buildUrl(farmId,turbineId));
    }
    
    generateTurbineReportURL(farmId: string, turbineId: string): Observable<ReportResponse> {
        let requestBody = {turbineId: turbineId};
        return this.http.post<ReportResponse>(this.buildUrl(farmId, turbineId)+'/report',requestBody);
    }
}