// src/app/core/core.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FarmService } from '../features/farms/application/farm.service';
import { IFarmService } from '../features/farms/application/farm.service.interface';
import { IFarmRepository } from '../domain/repositories/farm-repository.interface';
import { FarmRepository } from '../infrastructure/repositories/farm-repository';
import { TurbineRepository } from '../infrastructure/repositories/turbine-repository';
import { IJointRepository } from '../domain/repositories/joint-repository.interface';
import { JointRepository } from '../infrastructure/repositories/joint-repository';
import { IJointService } from '../features/farms/application/joint.service.interface';
import { JointService } from '../features/farms/application/joint.service';
import { IImageRepository } from '../domain/repositories/image-repository.interface';
import { ImageRepository } from '../infrastructure/repositories/image-repository';
import { ITurbineService } from '../features/farms/application/turbine.service.interface';
import { TurbineService } from '../features/farms/application/turbine.service';
import { ITurbineRepository } from '../domain/repositories/turbine-repository.interface';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: IImageRepository, useClass: ImageRepository },
    { provide: IFarmRepository, useClass: FarmRepository },
    { provide: IJointRepository, useClass: JointRepository },
    { provide: ITurbineRepository, useClass: TurbineRepository },
    { provide: IFarmService, useClass: FarmService },
    { provide: ITurbineService, useClass: TurbineService },
    { provide: IJointService, useClass: JointService },
  ]
})
export class CoreModule {

}
