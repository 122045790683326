import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Turbine } from "src/app/domain/models/turbine";
import { ITurbineRepository } from "src/app/domain/repositories/turbine-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TurbineRepository implements ITurbineRepository {

    private buildUrl( turbineId?: string, farmId?: string): string {
        if (turbineId)
            return `${environment.api}/farms/${farmId}/turbines/${turbineId}`
        return `${environment.api}/farms/${farmId}/turbines`
    }

    private buildStorageUrl(): string {
        return `${environment.apiUpload}/files`
    }

    constructor(private http: HttpClient) { }
    getAll(farmId: string): Observable<Turbine[]> {
        throw new Error("Method not implemented.");
    }
    getTurbineDetail(farmId: string, turbineId: string): Observable<Turbine> {
        console.log(this.buildUrl(farmId, turbineId));
        return this.http.get<Turbine>(this.buildUrl(farmId,turbineId));
    }
}