import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/shared/layout/service/app.layout.service';
import { AppSidebarComponent } from '../sidebar/app.sidebar.component';
import { AuthService } from '../../auth/auth-service';
import { BreadcrumbPattern } from '../breadcrumb/breadcrumb-pattern';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('searchinput') searchInput!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    searchActive: boolean = false;

    //These breadcrumb patterns are used in such a way that 
    breadcrumbPatterns: BreadcrumbPattern[] = [
            {id: "dashboard", pattern: ".*\/dashboard"},
            {id: "farms", pattern:".*\/farms"},
            {id: "farm", pattern:".*\/farms/[0-9]*"},
            {id: "turbine", pattern:".*\/turbines/[0-9]*"},
            {id: "joint", pattern:".*\/joints/[0-9]*"},
            {id: "projects", pattern: ".*\/projects"},
            {id: "project", pattern: ".*\/projects/[Aa-zZ0-9-]*"}
        ]

    constructor(public layoutService: LayoutService, public el: ElementRef, private authService: AuthService) { }
    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }
    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    logout() {
        this.authService.SignOut();
    }
}