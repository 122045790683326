export const environment = {
    appVersion: require('../../package.json').version,
    production: false,
    verticalBusiness: 'wind',
    api: 'https://dev.projecttracking.wind.web.api.texascontrols.com',
    apiUpload: 'https://dev.filestorage.api.texascontrols.com',
    firebase: {
        apiKey: "AIzaSyCp75la5ZatA-G2tmc0o9_X2MVBmgG53EQ",
        authDomain: "texas-controls.firebaseapp.com",
        projectId: "texas-controls",
        storageBucket: "texas-controls.appspot.com",
        messagingSenderId: "795062308999",
        appId: "1:795062308999:web:a0457aca03025afbe92822"
    },
    useEmulators: false,
    sentry: {
        dsn: "https://3dfece0cd2e8e0efa130806da95f036d@o4507254615310336.ingest.de.sentry.io/4508002938126416"
    }
};
