import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Joint } from "src/app/domain/models/joint";
import { JointDetail } from "src/app/domain/models/joint-detail";
import { IJointRepository } from "src/app/domain/repositories/joint-repository.interface";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class JointRepository implements IJointRepository {
    
    constructor(private http: HttpClient) { }

    private buildUrl(farmId: string, turbineId: string, jointId?: string): string {
        if (jointId){
            console.log(jointId);
            return `${environment.api}/farms/${farmId}/turbines/${turbineId}/joints/${jointId}`;
        }
        return `${environment.api}/farms/${farmId}/turbines/${turbineId}/joints`;
    }
        
    
    getJointsbyTurbineModelId(farmId: string, turbineId: string, turbineModelId: string): Observable<Joint[]> {
        return this.http.get<Joint[]>(this.buildUrl(farmId,turbineId) + `?turbineModelId=${turbineModelId}`); 
    }
    getJointDetailsById(farmId: string, turbineId: string, jointId: string): Observable<JointDetail> {
        return this.http.get<JointDetail>(this.buildUrl(farmId, turbineId, jointId)+'/detail');
    };
}